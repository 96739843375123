@use 'abstracts/variables' as var;

@mixin base {
  font-family: 'OpenSans-Bold' !important;
  text-transform: uppercase !important;
  display: inline-block !important;
  text-align: center !important;
  white-space: nowrap !important;
  vertical-align: middle !important;
  cursor: pointer !important;
  background-image: none !important;
  border: 0 none transparent !important;
  border-radius: 5px !important;
  // The remaining properties should be set dynamically.
  font-size: 14px !important;
  padding: 8px 25px !important;
  margin-left: 10px !important;
}

// Mixin that generates the default styles for a given button.
/// @param {string} $background-color - Sets the background color of the button.
/// @param {string} $font-color - Sets the font color of the button.
/// @param {string} $box-shadow - Sets the box shadow of the button.
/// @param {string} $border-color - Sets the border color of the button.
@mixin generate-default-button(
  $background-color,
  $font-color,
  $border-color: inherit,
  $box-shadow
) {
  background-color: $background-color;
  color: $font-color;
  border: 1px solid $border-color;
  box-shadow: $box-shadow;
}

/// Mixin that modifies the size of the button. Mostly used for screen size changes.  Currently only modifying the width but any sizing properties could be added.
/// @param {string} $width - Sets the width in any acceptable width value. (px, %, rem etc).
@mixin modify-button-size($width) {
  width: $width;
}

/// Mixin that sets the default state of a primary orange button.
@mixin primary-button-orange {
  @include generate-default-button(
    var.$primary-button-orange !important,
    var.$primary-white !important,
    var.$primary-button-orange !important,
    var.$primary-button-orange-shadow !important
  );
}

/// Mixin that sets the default hover state of button.
@mixin primary-button-orange-hover {
  &:hover {
    @include generate-default-button(
      var.$primary-button-orange-hover !important,
      inherit,
      var.$primary-button-orange-hover-border !important,
      var.$primary-button-orange-hover-shadow !important
    );
  }
}

/// Mixin to set the default focus state of button. Currently using a call to `generate-hover-state` only because the same rules apply.
@mixin primary-button-orange-focus {
  &:focus {
    @include generate-default-button(
      var.$primary-button-orange-focus !important,
      inherit,
      var.$primary-button-orange-focus-border !important,
      var.$primary-button-orange-focus-shadow !important
    );
  }
}

// Mixin that sets the default disabled state of button. Currently doesn't look like it's used anywhere.
@mixin primary-button-orange-disabled {
  @include generate-default-button(
    var.$primary-button-orange-disabled,
    var.$primary-white,
    unset,
    var.$primary-button-orange-focus-shadow
  );
}
