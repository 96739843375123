/// <reference path="../_bootstrap.scss" />
@import "../abstracts/fonts";
@import "../abstracts/functions";
@import "../abstracts/mixins";
@import "../abstracts/variables";

html, body, #bootstrap-overrides {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: $background-color;
    font-family: $primary-font-regular;
    color: $primary-text;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

.pointer {
    cursor: pointer;
}

.container {
    padding: rem(32);
}

.container-fluid {
    background-color: $background-color;
}

.iframe-error {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    z-index: 999999;
}

h1 {
    font-weight: 300;
    font-size: rem(34);

    @include media-breakpoint-down(xs) {
        font-size: rem(26);
    }
}

h2 {
    font-weight: 400;
    font-size: rem(25);

    @include media-breakpoint-down(xs) {
        font-size: rem(20);
    }
}

a {
    color: $primary-link;

    &:hover {
        color: $primary-link;
    }

    &.small-link {
        font-size: rem(14);
    }
}

.underline-hover-off {
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

.underline-hover-on {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.no-pad {
    padding: 0 !important;
    margin: 0 !important;
}

hr {
    width: 100%;
    border: 1px solid $btn-inactive;
}

/* -------------------------------------------------------- */
/* --------------------- Spinner -------------------------- */
/* -------------------------------------------------------- */
$spinner-width-sm: 1.5rem;
$spinner-height-sm: 1.5rem;

.spinner-container {
    //opacity: 1 !important;
    display: block;
    position: absolute;
    z-index: 1;
    height: rem(100);
    width: rem(100);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;

    &.relative {
        position: relative;
    }

    &.relativeWithTopPadding {
        position: relative;
        padding-top: rem(30);
    }

    &.relativeWithTopAndBottomPadding {
        position: relative;
        padding: rem(55) 0 rem(110);
    }

    &.fixedWithTopAndBottomPadding {
        position: fixed;
        padding: rem(55) 0 rem(110);
    }
}

.custom-spinner {
    width: rem(70);
    height: rem(70);
    color: $spinner;
}

button.has-promise-spinner {
    // This will hide spinner by default, and when "is-loading", the text is hidden and the spinner shows.
    // Apply to a button that is using [promiseBtn], and put button text within a <span></span>,
    // e.g.: <button class="btn btn-outline-primary has-promise-spinner" [promiseBtn]="isDownloading" (click)="downloadReport()">
    //          <span>Download your type description</span>
    //       </button>
    position: relative;

    &:not(.is-loading) .spinner-border {
        display: none;
    }

    &.is-loading span {
        visibility: hidden;

        &.spinner-border {
            left: calc(50% - #{$spinner-width-sm}/2);
            position: absolute;
            top: calc(#{$spinner-height-sm}/4);
            visibility: visible;
        }
    }
}

/* -------------------------------------------------------- */
/* ----------------------- Icons--------------------------- */
/* -------------------------------------------------------- */
.help-icon {
    @include pointer;
    display: inline-block;
    width: 18px;
    height: 18px;
    background: no-repeat center url("/assets/img/help.svg");

    &:hover {
        background: no-repeat center url("/assets/img/help-hover.svg");
    }
}

.download-item {
    @include pointer;
    text-align: center;
    padding-bottom: rem(16);

    .download-item-circle {
        background-color: $nav-link-background-hover;
        box-shadow: 0 0 rem(4) 0 rgba(75, 80, 90, 0.2);
        line-height: rem(54);
        height: rem(54);
        width: rem(54);
        -webkit-border-radius: rem(54);
        -moz-border-radius: rem(54);
        border-radius: rem(54);
        text-align: center;
        margin: 0 auto;
        color: white;

        .download-item-icon {
            background-repeat: no-repeat;
            height: rem(38);
            margin-left: rem(8);
            margin-top: rem(6);
            background-image: url(/assets/img/download.svg);

            &.processing {
                background-image: url(/assets/img/loading-spinner.svg);
                cursor: default;
            }
        }

        &.text-right {
            display: inline-block;
            margin-bottom: rem(-16);
            margin-right: rem(16);
        }

        &.text-bottom {
            display: inline-block;

            @include media-breakpoint-down(sm) {
                line-height: rem(45);
                height: rem(45);
                width: rem(45);
                -webkit-border-radius: rem(45);
                -moz-border-radius: rem(45);
                border-radius: rem(45);

                .download-item-icon {
                    height: rem(30);
                }
            }
        }
    }

    .download-item-text {
        font-weight: bold;
        color: $sub-nav-link;
        text-transform: uppercase;

        &:hover {
            color: $sub-nav-link;
        }

        &.text-bottom {
            @include media-breakpoint-down(sm) {
                font-size: rem(12);
            }
        }

        &.disabled {
            color: $disabled-text;
            cursor: default;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.show-more-show-less {
    @include pointer;
    text-align: center;
    padding-bottom: rem(16);

    .show-more-show-less-circle {
        background-color: $show-more-show-less-circle;
        box-shadow: 0 0 rem(4) 0 rgba(75, 80, 90, 0.2);
        line-height: rem(36);
        height: rem(36);
        width: rem(36);
        -webkit-border-radius: rem(36);
        -moz-border-radius: rem(36);
        border-radius: rem(36);
        text-align: center;
        margin: 0 auto;
        color: white;

        .show-more-show-less-icon.more::before {
            border-style: solid;
            border-width: rem(2) rem(2) 0 0;
            border-color: $show-more-show-less-chevron;
            content: '';
            display: inline-block;
            height: 0.45em;
            position: relative;
            transform: rotate(-45deg) scale(1) skew(10deg, 10deg);
            width: 0.45em;
        }

        .show-more-show-less-icon.less::before {
            border-style: solid;
            border-width: rem(2) rem(2) 0 0;
            border-color: $show-more-show-less-chevron;
            content: '';
            display: inline-block;
            height: 0.45em;
            position: relative;
            transform: rotate(135deg) scale(1) skew(10deg, 10deg);
            width: 0.45em;
            top: 0;
            margin-bottom: rem(3);
        }
    }
}

/* -------------------------------------------------------- */
/* ----------------- Chevron: ----------------------------- */
/* Chevron (top) <span class="chevron top"></span> -------- */
/* Chevron (right) <span class="chevron right"></span> ---- */
/* Chevron (bottom) <span class="chevron bottom"></span> -- */
/* <span class="chevron left"></span> Chevron (left) ------ */
/* -------------------------------------------------------- */
.chevron::before {
    border-style: solid;
    border-width: 0.12rem 0.12rem 0 0;
    content: '';
    display: inline-block;
    height: 0.45em;
    position: relative;
    transform: rotate(-45deg) scale(1.5);
    width: 0.45em;

    @include media-breakpoint-down(sm) {
        height: 0.35em;
        width: 0.35em;
    }
}

.chevron.top:before {
    margin-left: rem(30);
    transform: rotate(-45deg) scale(1) skew(10deg, 10deg);
}

.chevron.right:before {
    left: 0;
    transform: rotate(45deg) scale(1.5);
}

.chevron.bottom:before {
    top: 0;
    transform: rotate(135deg) scale(1) skew(10deg, 10deg);
    margin-left: rem(30);
    margin-bottom: rem(4);
}

.chevron.left:before {
    transform: rotate(-135deg) scale(1.5);
    margin: 0 rem(5) rem(2) 0;
}
/* -------------------------------------------------------- */
/* --------------------- Buttons -------------------------- */
/* -------------------------------------------------------- */
.btn {
    color: white;
    background-color: $btn-active;
    box-shadow: 4px 4px 6px 0 rgba(210, 210, 210, 0.8);
    border: none;
    position: relative;
    font-weight: bold;
    letter-spacing: 1px;
    padding-left: rem(20);
    padding-right: rem(20);
    min-width: rem(180);
    height: rem(36);

    &::before {
        content: "";
        width: 100%;
        height: 3px;
        position: absolute;
        right: 0;
        bottom: 100%;
        margin-bottom: -3px;
        background: linear-gradient(to bottom, #4ad6a0, rgba(60, 190, 141, 0.4)) 100% 0/3px;
    }

    &::after {
        content: "";
        width: 100%;
        height: 3px;
        position: absolute;
        right: 0;
        top: 100%;
        margin-top: -3px;
        background: linear-gradient(to bottom, rgba(0, 130, 80, 0.5), #1e7655) 100% 0/3px;
    }

    &:hover, &:focus {
        color: white;
        box-shadow: none;

        &::after, &::before {
            background: $btn-active;
        }
    }

    &.disabled, &:disabled {
        opacity: 1;
        background-color: $btn-inactive;

        &::before, &::after {
            background: $btn-inactive 100% 0/3px;
        }

        &:hover, &:focus {
            color: white;
            box-shadow: 4px 4px 6px 0 rgba(210, 210, 210, 0.8);
        }
    }

    &.inverse {
        color: $btn-active;
        background-color: white;
        border: $btn-active solid;
        border-radius: 0;
        line-height: 0;

        &::before, &::after {
            background: unset;
            height: unset;
            margin-top: unset;
        }
    }
}
/* --- To put a shadow box around a DIV --- */
.shadow {
    position: relative;
    box-shadow: 0 0 4px 4px rgba(198, 198, 198, 0.2);
    -moz-box-shadow: 0 0 4px 4px rgba(198, 198, 198, 0.2);
    -webkit-box-shadow: 0 0 4px 4px rgba(198, 198, 198, 0.2);
}

/* -------------------------------------------------------- */
/* --------------------- Forms ---------------------------- */
/* -------------------------------------------------------- */
/* for registration validation only */
form {
    label.input-section-label {
        color: $disabled-text;
        font-weight: bold;
    }

    label.is-invalid-label {
        color: $input-border-error;
        font-size: rem(13);
    }

    label.is-invalid-message {
        color: $input-border-error;
        font-family: $primary-font-regular;
        font-size: rem(14);
    }
}
/* -------------------------------------------------------- */
/* ------------------ Modal Overrides --------------------- */
/* -------------------------------------------------------- */
.modal-header {
    background-color: $modal-header-background;
    color: white;
    font-size: rem(26);
    padding: rem(10) rem(16);
    margin-left: -0.5px;

    .modal-title {
        color: $modal-header-text;
    }
}

.modal-footer {
    margin: auto;
    border: none;
    padding-bottom: rem(20);
}

.modal-close {
    background-image: url(/assets/img/close-static.svg); /* this was close-default.svg*/
    background-repeat: no-repeat;
    background-position: center;
    width: rem(32);

    &:hover {
        background-image: url(/assets/img/close-onhover.svg); /*this was close-hover.svg*/
        text-decoration: none;
    }
}


/* -------------------------------------------------------- */
/* ------------------- Category Box ----------------------- */
/* - This is a gray box with smaller, darker gray writing - */
/* ---------- inside, often appearing on Elevate ---------- */
/* -------------------------------------------------------- */
.category-box {
    padding: rem(28);
    background: $cateory-box-background;
    border: 1px solid $cateory-box-border;
    color: $cateory-box-text;
    font-size: rem(12);
    font-weight: 700;
    border-radius: .25rem;
}

/* -------------------------------------------------------- */
/* ---------------------- Video Box ----------------------- */
/* -------------------------------------------------------- */
.video {
    margin: rem(24) 10% 0 10%;

    @include media-breakpoint-down(md) {
        margin: rem(24) 0 0 0;
    }
}

.transcript {
    .show-more-show-less {
        font-weight: bold;
    }

    div[aria-expanded=true] {
        .chevron.bottom, .show-more-show-less.show {
            display: none;
        }
    }

    div[aria-expanded=false] {
        .chevron.top, .show-more-show-less.hide {
            display: none;
        }
    }

    p {
        padding-top: rem(16)
    }
}

/* -------------------------------------------------------- */
/* --------------- Registration Copyright ----------------- */
/* -------------------------------------------------------- */

sup.reg {
    font-size: 16px;
    top: 0;

    &.header {
        font-size: 26px;
    }

    &.sub-header {
        font-size: 20px;
    }
}

.popover {
    box-shadow: 0 0 10px rgba(102, 102, 102, 0.3) !important;
    border: none !important;
}

.popover-content {
    padding: 20px 20px;
}

.popover-content-nav-links {
    padding: 15px 20px;
}

.popover.top {
    margin-top: -20px;
}

.popover.right {
    margin-left: 20px;
}

.popover.bottom {
    margin-top: 20px;
}

.popover.left {
    margin-left: -20px;
}

.popover.top > .arrow {
    border-top-color: transparent;
    bottom: -10px;
}

.popover.top > .arrow:after {
    border: 10px solid #000000;
    border-color: transparent transparent #FFFFFF #FFFFFF;
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    box-shadow: -3px 3px 3px -1px rgba(102, 102, 102, 0.2);
}

.popover.right > .arrow {
    border-right-color: transparent !important;
    left: -10px;
}

.popover.right > .arrow:after {
    border: 10px solid #000000;
    border-color: transparent transparent #FFFFFF #FFFFFF;
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    box-shadow: -3px 3px 3px -1px rgba(102, 102, 102, 0.2);
}

.popover.bottom > .arrow {
    border-bottom-color: transparent !important;
    top: -10px;
}

.popover.bottom > .arrow:after {
    border: 10px solid #000000;
    border-color: transparent transparent #FFFFFF #FFFFFF;
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    box-shadow: -3px 3px 3px -1px rgba(102, 102, 102, 0.2);
}

.popover.left > .arrow {
    border-left-color: transparent !important;
    right: -10px;
}

.popover.left > .arrow:after {
    border: 10px solid #000000;
    border-color: transparent transparent #FFFFFF #FFFFFF;
    -moz-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    box-shadow: -3px 3px 3px -1px rgba(102, 102, 102, 0.2);
}

.popover.top-left .arrow {
    border-top-color: transparent;
}

.popover.top-left .arrow:after {
    border: 10px solid #000000;
    border-color: transparent transparent #FFFFFF #FFFFFF;
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    box-shadow: -3px 3px 3px -1px rgba(102, 102, 102, 0.2);
}

.popover.top-right .arrow {
    border-top-color: transparent;
}

.popover.top-right .arrow:after {
    border: 10px solid #000000;
    border-color: transparent transparent #FFFFFF #FFFFFF;
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    box-shadow: -3px 3px 3px -1px rgba(102, 102, 102, 0.2);
}

.popover.bottom-left .arrow {
    border-bottom-color: transparent !important;
}

.popover.bottom-left .arrow:after {
    border: 10px solid #000000;
    border-color: transparent transparent #FFFFFF #FFFFFF;
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    box-shadow: -3px 3px 3px -1px rgba(102, 102, 102, 0.2);
}

.popover.bottom-right .arrow {
    border-bottom-color: transparent !important;
}

.popover.bottom-right .arrow:after {
    margin-left: -17px;
    border: 10px solid #000000;
    border-color: transparent transparent #FFFFFF #FFFFFF;
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    box-shadow: -3px 3px 3px -1px rgba(102, 102, 102, 0.2);
}


.elevate-popover-heading {
    font-family: "OpenSans-Bold";
    font-size: 13px;
    color: #528C9C;
    padding-bottom: 15px;
    text-transform: uppercase;
    background-color: transparent !important;
    border-bottom-color: transparent !important;
}

.elevate-popover-heading.bulk-VT {
    font-size: 15px;
}

.elevate-popover-body {
    font-family: "OpenSans-Regular";
    font-size: 14px;
    color: #4B505A;
}

.elevate-popover-close-icon {
    margin-top: -15px;
    margin-right: -15px;
}

.elevate-popover-close-icon.bulk-VT {
    margin-top: -5px;
    margin-right: -8px;
}

.elevate-popover-sort-items {
    padding: 0 0 10px 10px;
    margin-top: -5px;
}

.elevate-popover-sort-item {
    font-family: "OpenSans-Regular";
    font-size: 14px;
    color: #666666;
    cursor: pointer;
}

.elevate-popover-sort-item.selected {
    font-family: "OpenSans-Bold";
}

.elevate-popover-sort-item:hover {
    color: #E15F3B;
}

.close-icon-container {
    -webkit-appearance: none;
    cursor: pointer;
    background: transparent;
    border: 0;
    opacity: 0.8;
    float: right;
    padding: 0;
}

.close-icon-container:hover > .close-icon {
    background: no-repeat center / 100% url("/assets/img/close-onhover.svg");
}

.close-icon {
    width: 30px;
    height: 30px;
    background: no-repeat center / 30px url("/assets/img/close-static.svg");
}

.close-icon:hover {
    background: no-repeat center / 100% url("/assets/img/close-onhover.svg");
}
