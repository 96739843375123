/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * 
 * These are the three main libraries that are needed for SASS files to compile if they reference bootstrap functions
 *    ex.  @include media-breakpoint-down(sm)
 * 
 * If you need to include bootstrap libraries, uncomment them in the _bootstrap-libraries.scss file
 * 
 */

@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
