$font-path: "/assets/fonts"; // Path relative to this file

/* Regular */
@font-face {
    font-family: 'OpenSans-Regular';
    src: url('#{$font-path}/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSans-Italic';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('#{$font-path}/open-sans-v23-latin-regular.eot'); /* IE9 Compat Modes */
    src: url('#{$font-path}/open-sans-v23-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$font-path}/open-sans-v23-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$font-path}/open-sans-v23-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('#{$font-path}/open-sans-v23-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$font-path}/open-sans-v23-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'OpenSans-Light';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('#{$font-path}/butcherman-v14-latin-regular.eot'); /* IE9 Compat Modes */
    src: url('#{$font-path}/open-sans-v23-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$font-path}/open-sans-v23-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$font-path}/open-sans-v23-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('#{$font-path}/open-sans-v23-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$font-path}/open-sans-v23-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'OpenSans-LightItalic';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url('#{$font-path}/open-sans-v23-latin-regular.eot'); /* IE9 Compat Modes */
    src: url('#{$font-path}/open-sans-v23-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$font-path}/open-sans-v23-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$font-path}/open-sans-v23-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('#{$font-path}/open-sans-v23-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$font-path}/open-sans-v23-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}


/* Semibold */
@font-face {
    font-family: 'OpenSans-Semibold';
    src: url('#{$font-path}/OpenSans-Semibold.ttf') format('truetype');
}


@font-face {
    font-family: 'OpenSans-SemiBoldItalic';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url('#{$font-path}/open-sans-v23-latin-regular.eot'); /* IE9 Compat Modes */
    src: url('#{$font-path}/open-sans-v23-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$font-path}/open-sans-v23-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$font-path}/open-sans-v23-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('#{$font-path}/open-sans-v23-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$font-path}/open-sans-v23-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* removed original 'OpenSans-Bold'*/

/* Bold */
@font-face {
    font-family: 'OpenSans-Bold';
    src: url('#{$font-path}/OpenSans-Bold.ttf') format('truetype');
}


@font-face {
    font-family: 'OpenSans-BoldItalic';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('#{$font-path}/open-sans-v23-latin-regular.eot'); /* IE9 Compat Modes */
    src: url('#{$font-path}/open-sans-v23-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$font-path}/open-sans-v23-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$font-path}/open-sans-v23-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('#{$font-path}/open-sans-v23-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$font-path}/open-sans-v23-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'OpenSans-ExtraBold';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('#{$font-path}/open-sans-v23-latin-regular.eot'); /* IE9 Compat Modes */
    src: url('#{$font-path}/open-sans-v23-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$font-path}/open-sans-v23-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$font-path}/open-sans-v23-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('#{$font-path}/open-sans-v23-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$font-path}/open-sans-v23-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'OpenSans-ExtraBoldItalic';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('#{$font-path}/open-sans-v23-latin-regular.eot'); /* IE9 Compat Modes */
    src: url('#{$font-path}/open-sans-v23-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$font-path}/open-sans-v23-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('#{$font-path}/open-sans-v23-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('#{$font-path}/open-sans-v23-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$font-path}/open-sans-v23-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'FontAwesome';
    src: url('#{$font-path}/fontawesome-webfont.eot'); /* IE9 Compat Modes */
    src: url('#{$font-path}/fontawesome-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('#{$font-path}/fontawesome-webfont') format('woff2'), /* Super Modern Browsers */
    url('#{$font-path}/fontawesome-webfont.woff') format('woff'), /* Modern Browsers */
    url('#{$font-path}/fontawesome-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
    url('#{$font-path}/fontawesome-webfont.svg#OpenSans') format('svg'); /* Legacy iOS */
}
