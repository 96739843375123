/**********************************
    GLOBAL VARIABLES CSS FILE
**********************************/

//$grid-breakpoints: ( xs: 0, sm: 480px, md: 768px, lg: 992px, xl: 1200px );
//$container-max-widths: ( /*sm: 432px, md: 716px,*/ lg: 1245px);

/* COLORS */
$primary-text: #626262;
$primary-link: #217092;
// $background-color: #f7f7f7;
$background-color: #ffffff;
$primary-white: #ffffff;

$nav-link: #4b505a;
$nav-link-hover: #2bbb9c;
$nav-link-background-hover: #f7f7f7;
$nav-link-border-hover: #e1dfe3;
$nav-link-dropdown-border: #666666;
$sub-nav-link: #217092;

$disabled-text: #767676;
$disabled-border: #f4f4f4;
$input-border-editable: #7d8e98;
$input-border-focus: #30779b;
$input-border-error: #c63006;
$custom-select: #32353c;

$btn-active: #3ca67e;
$btn-inactive: #cccdce;

$spinner: #00c5c7;

$time-left-circle: #3ca67e;
$time-left-circle-number: #32353c;
$time-left-expired: #ca1d1d;
$ribbon: #eee;

$tab-text: #626262;
$status-circle: #00c5c7;
$status-circle-number: #3e4747;
$materials-header: #98989a;
$materials-interactive: #fba647;

$schedule-circle: #00c5c7;
$schedule-circle-text: #32353c;
$schedule-circle-connector-active: #54cfd6;
$schedule-circle-connector-inactive: #cccdce;
$schedule-step-inactive: #979797;
$long-time-warning: #f99442;
$lms-progress: #00969f;

$alert-success: #3ca67e;
$alert-error: #c9330a;
$alert-info: #4b505a;
$alert-warning: #fff3cd;
$alert-warning-text: #856404;

$show-more-show-less-circle: #f4f4f4;
$show-more-show-less-chevron: #55575b;

$invite-respondents-error: #ff0000;

$modal-header-background: #fff;
$modal-header-text: #528c9c;

$cateory-box-background: rgba(102, 102, 102, 0.05);
$cateory-box-text: #666666;
$cateory-box-border: #e1dfe3;

$screenshot-caption: #959597;

/*registration*/
$modal-color-1: #eeeeee;

/* FONTS */
$primary-font-light: 'OpenSans-Light';
$primary-font-regular: 'OpenSans-Regular';
$primary-font-semibold: 'OpenSans-Semibold';
$primary-font-bold: 'OpenSans-Bold';
$primary-font-extrabold: 'OpenSans-ExtraBold';
$primary-font-light-italic: 'OpenSans-LightItalic';
$primary-font-regular-italic: 'OpenSans-Italic';
$primary-font-semibold-italic: 'OpenSans-SemiboldItalic';
$primary-font-bold-italic: 'OpenSans-BoldItalic';
$primary-font-extrabold-italic: 'OpenSans-ExtraBoldItalic';

/* Primary Button Orange */
$primary-button-orange: #e15f3b;
$primary-button-orange-shadow: inset 0 0 7px 0 rgba(198, 48, 6, 0.75);
$primary-button-orange-hover: #be4c2f;
$primary-button-orange-hover-border: rgba(151, 34, 2, 0.75);
$primary-button-orange-hover-black: rgba(102, 102, 102, 0.251);
$primary-button-orange-hover-shadow: inset 0 0 7px 0
    $primary-button-orange-hover-border,
  0 0 5px 0 $primary-button-orange-hover-black;
$primary-button-orange-focus: #f58363;
$primary-button-orange-focus-border: rgba(238, 77, 32, 0.75);
$primary-button-orange-focus-shadow: inset 0 0 7px 0
  $primary-button-orange-focus-border;
$primary-button-orange-disabled: $primary-button-orange-focus;

$card-content-background: #fcfcfc;

$input-placeholder-color: #a8a8a8;
