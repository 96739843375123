@use 'abstracts/button-base';
@import 'abstracts/_functions';
@import 'abstracts/_mixins';
@import 'abstracts/_variables';

// These have to be loaded before base/Styles so that
// 1. it can use their mixins and functions
// 2. can override as required (anything that cannot be changed by variables alone)
@import '_bootstrap';
@import '_bootstrap-libraries';

@import 'base/Styles.scss';

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';
@import '../../node_modules/bootstrap/scss/bootstrap.scss';

/* Importing Datepicker SCSS file. */
// @import 'ngx-bootstrap/datepicker/bs-datepicker';

body header.local_header {
  margin-bottom: 25px;
  margin-top: 0;
}

body header.local_header h1 {
  font: normal 36px 'OpenSans-Light' !important;
  color: #528c9c !important;
  float: left;
  margin-top: 0;
  margin-bottom: 0;
}

/*style.css 335*/
select {
  height: 25px;
  font: 12px 'Lucida Grande', sans-serif;
  padding: 0;
  cursor: pointer;
  background-color: #f1f4f5;
}
////style.css 267
.button,
button,
input,
select,
textarea {
  font-size: 12px;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}

button,
.button {
  cursor: pointer;
  padding: 6px 20px;
  font: bold 12px arial, sans-serif;
  color: #40403f;
  border: 1px solid #e5e4e4;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #fff;
  background: -moz-linear-gradient(top, #fff 50%, #f1f4f5 51%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(50%, #fff),
    color-stop(51%, #f1f4f5)
  );
  background: -webkit-linear-gradient(top, #fff 50%, #f1f4f5 51%);
  background: -o-linear-gradient(top, #fff 50%, #f1f4f5 51%);
  background: -ms-linear-gradient(top, #fff 50%, #f1f4f5 51%);
  background: linear-gradient(top, #fff 50%, #f1f4f5 51%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff',endColorstr='#f1f4f5',GradientType=0);
}

.button,
button,
input,
select,
textarea {
  font-size: 12px;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}

.button,
button,
input {
  line-height: normal;
}

button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  cursor: pointer;
  -webkit-appearance: button;
  *overflow: visible;
}

/*12-10-21 12:38*/
//registration.css 260
select.form-select.unselected,
select.form-select option:first-child {
  color: #a8a8a8;
}
//registration:261
input[type='text'],
input[type='email'],
input[type='date'],
input[type='number'],
input[type='password'],
input[type='tel'],
select,
select.form-select,
textarea {
  padding: 7px 10px;
  box-size: inherit;
  box-shadow: none;
  border: 1px inset #ebe9ed;
  outline: none;
  border-color: rgba(0, 0, 0, 0.4);
  background-color: #fff;
}
//style.css 7820
/* SELECT DROPDOWN */
.form-select {
  display: block;
  width: 100%;
  height: 40px;
  padding: 8px 12px;
  font-family: 'OpenSans-Regular';
  font-size: 16px;
  color: #666666;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid rgba(102, 102, 102, 0.4);
  border-radius: 5px;
  text-align: left;
}

.form-select:focus {
  /*border: none;*/
  outline: 0;
  -webkit-box-shadow: 0 0 5px 0 rgba(102, 102, 102, 0.3);
  box-shadow: 0 0 5px 0 rgba(102, 102, 102, 0.3);
}

/*12-10-21 12:38*/
.custom-radio-container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
  cursor: pointer;
  font-family: 'OpenSans-Regular' !important;
  font-size: 14px !important;
  color: #666666;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-radiomark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: solid 1px #a2a6b2;
}

.custom-radio-container:hover input ~ .custom-radiomark {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #e15f3b;
}

.custom-radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-radiomark:after {
  content: '';
  position: absolute;
  display: none;
  left: 6px;
  top: 6px;
  width: 0.04em;
  height: 0.04em;
  border: solid white;
  border-radius: 50px;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom-radio-container input:checked ~ .custom-radiomark {
  border: #e15f3b solid 1px;
  background-image: linear-gradient(135deg, #e15f3b, #e15f3b);
}

.validationMessage {
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #c63006;
}

.custom-radio-container {
  margin-bottom: 0;
}

.input-section-label {
  font-family: 'OpenSans-Bold';
  font-size: 13px;
  color: #666666;
  text-transform: uppercase;
}

.userradio {
  margin-top: 10px;
  line-height: 20px;
  padding-left: 15px;
  margin-left: 5px;
  font-size: 0.75em;
}

.userradio ~ span {
  line-height: rem(25);
}

.floating-tooltip {
  width: 270px;
  color: #fff;
  text-align: left;
  z-index: 1;
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  line-height: 1.42857143;
}

.section-header {
  font-family: 'OpenSans-Bold';
  font-size: 15px;
  color: #174f61;
  text-transform: uppercase;
}
/* NEW INPUTS */
/* INPUTS */
.form-input {
  display: block;
  width: 100%;
  padding: 8px 12px;
  font-family: 'OpenSans-Regular';
  font-size: 16px;
  color: #4b505a;
  background-color: #ffffff !important;
  border: 1px solid rgba(102, 102, 102, 0.4);
  border-radius: 5px;
  /*-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
	-webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
	-o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	-moz-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;*/
}

.form-input:focus {
  /*border: none;*/
  outline: 0;
  -webkit-box-shadow: 0 0 5px 0 rgba(102, 102, 102, 0.3);
  box-shadow: 0 0 5px 0 rgba(102, 102, 102, 0.3);
}

input::-moz-placeholder {
  font-family: 'OpenSans-Regular';
  color: #a8a8a8;
}

input:-ms-input-placeholder {
  font-family: 'OpenSans-Regular';
  color: #a8a8a8;
}

input::-webkit-input-placeholder {
  font-family: 'OpenSans-Regular';
  color: #a8a8a8;
}

/* NEW BUTTONS */
.btn-tpl-lg {
  font-family: 'OpenSans-Bold' !important;
  text-transform: uppercase !important;
  display: inline-block !important;
  text-align: center !important;
  white-space: nowrap !important;
  vertical-align: middle !important;
  cursor: pointer !important;
  background-image: none !important;
  border: 0 none transparent !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  padding: 8px 25px !important;
  margin-left: 10px !important;
}

.btn-tpl-sm {
  font-family: 'OpenSans-Bold' !important;
  text-transform: uppercase !important;
  display: inline-block !important;
  text-align: center !important;
  /* white-space: nowrap !important;*/
  vertical-align: middle !important;
  cursor: pointer !important;
  background-image: none !important;
  border: 0 none transparent !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  padding: 5px 20px !important;
  margin-left: 10px !important;
}

.btn-tpl-xs {
  font-family: 'OpenSans-Bold' !important;
  text-transform: uppercase !important;
  display: inline-block !important;
  text-align: center !important;
  white-space: nowrap !important;
  vertical-align: middle !important;
  cursor: pointer !important;
  background-image: none !important;
  border: 0 none transparent !important;
  border-radius: 5px !important;
  font-size: 11px !important;
  padding: 5px 10px !important;
  margin-left: 5px !important;
}

.btn-tpl-xs:hover {
  color: #ffffff !important;
  text-decoration: none !important;
}

.btn-tpl-lg:hover {
  color: #ffffff !important;
  text-decoration: none !important;
}

.btn-tpl-lg.btn-disabled-primary:hover,
.btn-tpl-lg.btn-disabled-secondary:hover {
  color: #e1dfe3 !important;
  text-decoration: none !important;
}

.btn-teal-primary {
  color: #ffffff;
  background-color: #2bbb9c;
  box-shadow: inset 0 0 7px 0 rgba(0, 128, 100, 0.75);
  border: 1px solid #2bbb9c;
}

.btn-teal-primary:hover {
  color: #ffffff;
  background-color: #23a187;
  box-shadow: inset 0 0 7px 0 rgba(0, 93, 74, 0.75),
    0 0 5px 0 rgba(102, 102, 102, 0.25);
  border: 1px solid rgba(0, 93, 74, 0.75);
}

.btn-teal-primary:focus {
  color: #ffffff;
  background-color: #72d2bd;
  box-shadow: inset 0 0 7px 0 rgba(20, 176, 142, 0.75);
  border: 1px solid rgba(20, 176, 142, 0.75);
}

.btn-teal-primary-disabled {
  color: #ffffff;
  background-color: #72d2bd;
  box-shadow: inset 0 0 7px 0 rgba(20, 176, 142, 0.75);
}

// Mobile.
@media (max-width: 767px) {
  .btn-orange-primary {
    @include button-base.modify-button-size(15rem);
    @include button-base.primary-button-orange;
    @include button-base.primary-button-orange-hover;
    @include button-base.primary-button-orange-focus;
  }

  .btn-orange-primary-disabled,
  .btn-orange-primary-disabled:hover {
    @include button-base.primary-button-orange-disabled;
  }
}

@media (min-width: 768px) {
  .btn-orange-primary {
    @include button-base.primary-button-orange;
    @include button-base.primary-button-orange-hover;
    @include button-base.primary-button-orange-focus;
  }
  .btn-orange-primary-disabled,
  .btn-orange-primary-disabled:hover {
    @include button-base.primary-button-orange-disabled;
  }
}

.btn-orange-secondary {
  color: #e15f3b;
  background-color: #ffffff;
  border: 1px solid #e15f3b !important;
  box-shadow: inset 0 0 2px 0 rgba(250, 55, 1, 0.75);
}

.btn-orange-secondary:hover {
  color: #ffffff;
  background-color: #e15f3b;
  box-shadow: inset 0 0 7px 0 rgba(198, 48, 6, 0.75),
    0 0 5px 0 rgba(102, 102, 102, 0.25);
  text-decoration: none;
}

.btn-orange-secondary:focus {
  color: #ffffff;
  background-color: #f58363;
  box-shadow: inset 0 0 7px 0 rgba(238, 77, 32, 0.75);
}

.btn-orange-secondary-disabled {
  color: #ffffff;
  border: 1px solid #e15f3b;
  background-color: #f58363;
  box-shadow: inset 0 0 7px 0 rgba(238, 77, 32, 0.75);
}

.section-subheader-redesign {
  font-family: 'OpenSans-Semibold';
  font-size: 20px;
  color: #528c9c;
}

/* Create a custom checkbox */
.register-checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 17px;
  width: 17px;
  border-radius: 2px;
  background-color: #fcfcfc;
  border: solid 1px #a2a6b2;
}
/* Create the checkmark/indicator (hidden when not checked) This provides the little white 'dot'*/
.register-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.agreement-checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 17px;
  width: 17px;
  border-radius: 2px;
  background-color: #fcfcfc;
  border: solid 1px #a2a6b2;
}

.register-check-container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-family: 'OpenSans-Regular';
  font-size: 14px !important;
  color: #666666;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.register-check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* On mouse-over, add a grey background color */
.register-check-container:hover input ~ .register-checkmark {
  /*background-color: #ccc;*/
  /*box-shadow: 0 0 0 1px #fff, 0 0 0 3px #e15f3b;*/
}

/* When the checkbox is checked, add a red background */
.register-check-container input:checked ~ .register-checkmark {
  border: #e15f3b solid 1px;
  background-image: linear-gradient(135deg, #e15f3b, #e15f3b);
}

/* Show the checkmark when checked */
.register-check-container input:checked ~ .register-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.register-check-container .register-checkmark:after {
  left: 6px;
  top: 6px;
  width: 0.04em;
  height: 0.04em;
  border: solid white;
  border-radius: 50px;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* When the checkbox is checked, add a red background */
.register-check-container input:checked ~ .agreement-checkmark {
  border: #e15f3b solid 1px;
  background-image: linear-gradient(135deg, #e15f3b, #e15f3b);
}

.agreement-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.register-check-container input:checked ~ .agreement-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.register-check-container .agreement-checkmark:after {
  left: 6px;
  top: 6px;
  width: 0.04em;
  height: 0.04em;
  border: solid white;
  border-radius: 50px;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* NEW LINKS */
.std-primary-link {
  font-family: 'OpenSans-Regular' !important;
  font-size: 15px !important;
  color: #528c9c !important;
  text-decoration: underline;
}

.std-primary-link:hover {
  color: #e15f3b !important;
  cursor: pointer !important;
  text-decoration: underline !important;
}

.std-primary-link-disabled {
  color: #666666;
  opacity: 0.7;
}

.field-header-note {
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  color: #a8a8a8;
  text-transform: none;
}

.actionbutton {
  position: relative;
  bottom: -25px;
  text-align: right;
}

/* MODALS */
.modal-title,
.modal-body {
  line-height: 1.4;
}

.modal-header-redesign {
  font-family: 'OpenSans-Light';
  font-size: 26px;
  color: #528c9c;
}

.modal-body,
.modal-body > label:not(.input-section-label) {
  font-family: OpenSans-Regular;
  font-size: 16px;
  color: #4b505a;
}

.modal .container,
.modal .container-fluid {
  background-color: #ffffff;
}

.modal-content a.std-link-bold-underline,
.std-link-bold-underline {
  font-family: 'OpenSans-Bold';
  font-size: 15px;
  color: #528c9c;
  text-decoration: underline !important;
}

.modal-content {
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-right: 20px;
}

.modal-header {
  border-style: none;
}

.modal-footer {
  border-style: none;
  padding-top: 0;

  a {
    white-space: nowrap;
  }
}

.modal-header a.modal-close {
  margin: 0;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 23px;
  height: 23px;
  border-radius: 23px;
  color: #ffe300;
  font-size: 9px;
  opacity: 1;
  z-index: 10;
}

body .tooltip-inner {
  background-color: #666666;
}

.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #666666 !important;
}

.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: #666666 !important;
}

.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.bs-tooltip-left .arrow::before {
  border-left-color: #666666 !important;
}

.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: #666666 !important;
}

@media (max-width: 767px) {
    .content-container {
        box-shadow: unset;
        padding: unset;
        background-color: unset;
    }
}

@media (min-width: 768px) {
    .content-container {
        box-shadow: 0px 3px 7px 0 rgba(102, 102, 102, 0.25);
        padding: 2rem;
        background-color: $card-content-background;
    }
}

#contentConfirmation > div > div.content {
    background-color: $card-content-background !important;
}